<template>
  <div class="h-backgroud-color_body">
    <van-sticky>
      <van-search v-model="name" shape="round" background="#fff" placeholder="请输入救援项目/车牌号/订单号" @search="onSearch"> </van-search>
    </van-sticky>
    <van-tabs v-model="active" sticky offset-top="54px">
      <van-tab title="待处理"><OrderList ref="orderListRef0" type="0" :name="name"/></van-tab>
      <van-tab title="已处理"><OrderList ref="orderListRef1" type="1" :name="name"/></van-tab>
    </van-tabs>
  </div>
</template>
<script>
import OrderList from "../components/OrderList";
import dayjs from "dayjs";
export default {
  name: "ProductOrderList",
  components: { OrderList },
  provide() {
    return {
      pageContext: this
    };
  },
  data() {
    return {
      active: 0,
      name: ""
    };
  },
  watch: {
    active: function() {
      this.onSearch();
    }
  },
  methods: {
    dayjs,
    onSearch() {
      if (!this.$refs[`orderListRef${this.active}`]) {
        return false;
      }
      this.$refs[`orderListRef${this.active}`].onSearch();
    }
  }
};
</script>
<style lang="scss" scoped></style>
