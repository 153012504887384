<script>
export default {
  functional: true,
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  render: (h, ctx) => {
    const { row } = ctx.props;
    let icon = h(
      "div",
      {
        style:
          "margin-left: 3px;background: rgb(89 126 247);border: 1px solid rgb(89 126 247);color: rgb(255 255 255);margin-top: 3px;width: 22px;height: 22px;border-radius: 100%;font: 12px Arial, sans-serif;justify-content: center;align-items: center;text-align: center;display: flex;"
      },
      row.orderType === "2" ? "空" : "异"
    );
    return row.orderType === "2" || row.orderType === "3" ? h("div", { style: "display: inline-flex;" }, [icon]) : h("div", {}, []);
  }
};
</script>

<style></style>
