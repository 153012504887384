<template>
  <div class="h-backgroud-color_body">
    <HYList ref="HYList" v-bind="listConfig">
      <template #columnHead="{ row }">
        <div class="row">
          <div class="row-head h-flex h-row-betwee n">
            <span style="font-size: 16px;">订单号：{{ row.orderNo }}<OrderStatusShow :row="row"/></span>
          </div>
          <span class="h-primary-color" style="font-size: 16px;float: right;margin-top: -27px;padding-right: 20px;">{{ row.orderStatus | dictFormat("RSC_ORDER_STATUS") }}</span>
          <div class="row-content">
            <div class="h-font-md h-flex">
              <span class="row-content_title">救援项目：</span>
              <span class="project-item">{{ row.projectName }}</span>
            </div>
            <div class="h-font-md h-flex">
              <span class="row-content_title">被救援车牌号：</span>
              <span class="project-item">{{ row.rescuePlateNo }}</span>
            </div>
            <div class="h-font-md h-flex">
              <span class="row-content_title">救援地址：</span>
              <span class="project-item" style="min-height: 42px;">{{ row.rescueAddr }}</span>
            </div>
            <div class="h-font-md h-flex">
              <span class="row-content_title">救援目的地：</span>
              <span class="project-item" style="min-height: 42px;">{{ row.destinationAddr }}</span>
            </div>
          </div>
          <div class="row-foot h-flex h-row-right">
            <van-button v-if="row.orderStatus === '2'" round type="primary" size="small" @click="orderTake(row)">接单</van-button>
            <van-button v-if="row.orderStatus === '2'" round type="danger" size="small" @click="returnOrder(row)">取消接单</van-button>
            <van-button v-if="row.orderStatus === '3'" round type="primary" size="small" @click="dispatch(row)">派单</van-button>
            <van-button round v-if="row.orderStatus === '4' && ['0', '1'].includes(row.driverStatus) && row.orderType === '1'" type="primary" size="small" @click="dispatch(row)">重新派单</van-button>
            <van-button round type="primary" size="small" @click="toDetail(row)">查看</van-button>
          </div>
        </div>
      </template>
      <template #empty>
        <div class="none-tip">
          <van-image :src="require('@/assets/images/none_data.jpg')" />
          <div>无查询信息！</div>
        </div>
      </template>
    </HYList>
  </div>
</template>
<script>
import HYList from "@/components/List/index";
import dayjs from "dayjs";
import { request } from "@/api/service";
import OrderStatusShow from "@/views/product/components/OrderStatusShow";
export default {
  name: "OrderList",
  components: { HYList, OrderStatusShow },
  props: {
    name: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      listConfig: {
        styleObj: {
          minHeight: null
        },
        api: {
          url: this.type === "0" ? "/acs-rescue/order/wx/listDownstreamToProcess" : "/acs-rescue/order/wx/listDownstreamProcessed"
        },
        form: {
          // terminal:"3",//移动端
          unionQuery: this.name
        },
        column: []
      }
    };
  },
  watch: {
    name: function(val) {
      this.listConfig.form.unionQuery = this.name;
      this.onSearch();
    }
  },
  methods: {
    dayjs,
    onSearch() {
      this.$refs.HYList.onRefresh();
    },
    toDetail(row) {
      this.$router.push({
        path: "/product/order/detail",
        query: {
          id: row.id
        }
      });
    },
    dispatch(row) {
      const { id, dispatchId } = row;
      this.$router.push({
        path: "/product/order/dispatch",
        query: {
          id,
          dispatchId
        }
      });
    },
    async orderTake(row) {
      const { id, dispatchId } = row;
      // 调用接单接口
      try {
        await request({
          url: "/acs-rescue/order/orderTake",
          method: "post",
          data: { id }
        });
        this.$router.push({
          path: "/product/order/dispatch",
          query: {
            id,
            dispatchId
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    returnOrder(row) {
      const { id } = row;
      this.$dialog.confirm({
        title: "取消确认",
        message: `确定要取消接收当前订单吗?`,
        beforeClose: (action, done) => {
          if (action !== "confirm") {
            done();
            return false;
          }
          request({
            url: `/acs-rescue/order/returnOrder`,
            method: "post",
            data: {
              id
            }
          })
            .then(() => {
              this.$toast("已取消接单");
              this.onSearch();
              done();
            })
            .catch(() => {
              done(false);
            });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/mixins/hairline.scss";
.row {
  padding: 10px 0;
  .project-item {
    width: 100%;
    text-align: right;
  }
  ::v-deep .van-button--default {
    background-color: #f0f0f0;
  }
  &-head {
    font-size: $font-size-lg;
    padding: 6px 20px;
    position: relative;
    &::after {
      @include hairline-bottom($border-color, $padding-xs, $padding-xs, $border-width-xs);
    }
  }
  &-content {
    padding: 6px 20px;
    background-repeat: no-repeat;
    background-position: 90% center;
    background-size: 70px;
    div {
      padding: 2px 0;
    }
    &_title {
      color: #8c8c8c;
      white-space: nowrap;
      text-align: right;
    }
  }
  &-foot {
    border-top: 2px solid $border-color;
    padding: 6px 20px;
    .van-button {
      margin-right: 5px;
    }
  }
}
.none-tip {
  margin: 10px;
  border-radius: 5px;
  background-color: $white;
  ::v-deep .van-image {
    margin-top: 20px;
  }
}
::v-deep .van-pull-refresh {
  min-height: calc(100vh - 158px);
}
</style>
